import { useState, useEffect, useCallback } from 'react';

import { ID } from '@core/models';
import {
    ConfidenceLevel,
    OperatorFilter,
    useCountOperatorsQuery,
    useGetAggregationsQuery,
} from '@graphql/generated/graphql';
import { useLocale } from '@core/utils/locale';
import { capitalize } from '@core/utils/string';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import useSegmentations from '@core/api/useSegmentation';
import useRanges, { RangeDTO, RangesTypesEnum } from '@core/api/useRanges';
import { CRITERIA } from '@core/hooks/useAdvancedSearchCriteria';
import { LocalesEnum, OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { OperatorAggregations, OperatorAggregationsModel } from '@core/models/OperatorAggregations';
import { CriteriaData, CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import {
    buildDropdownOptionTitle,
    parseRanges,
} from '@pages/OperatorTargetingCriteria/components/SegmentsCriteriaContent/GeneralCriteria/General.helpers';
import { filterAndParseSegmentsChannels } from '@pages/OperatorTargetingCriteria/helpers/Classification.helpers';

export type useGetOperatorSummaryDataReturnType = {
    activeCountryCriterion: CriterionParam | undefined;
    aggregations: OperatorAggregationsModel;
    resultsCount: number;
    selectedOptionId: ID;
    setSelectedOptionId: (id: ID) => void;
    actions: {
        onDrillDownSegments: (segmentId: ID, segmentName: string) => void;
        onDrillDownChainsVsIndependents: (id: ID) => void;
        onDrillDownAnnualSalesRange: (annualSalesRangeID: ID, annualSalesRangeName: string) => void;
        onDrillDownConfidenceLevel: (level: ConfidenceLevel) => void;
    };
};

export const COUNTS_ID = 'counts';
export const PERCENTS_ID = 'percents';
export const DRILL_DOWN_LOCAL_STORAGE = 'DrillDownResults';
const INDEPENDENTS_ID = 'independent';
const INDEPENDENTS_CODE = 'R06.WW98';

function useGetOperatorSummaryData(): useGetOperatorSummaryDataReturnType {
    const { locale } = useLocale();
    const [selectedOptionId, setSelectedOptionId] = useState<ID>(COUNTS_ID);
    const [chainParams, setChainParams] = useState<CriterionParam[]>([]);
    const [annualSalesRangeParams, setAnnualSalesRangeParams] = useState<CriterionParam[]>([]);
    const [aggregations, setAggregations] = useState<OperatorAggregationsModel>(
        new OperatorAggregations(undefined, undefined, undefined, locale),
    );

    const {
        criteria,
        searchFilters,
        getCriterionByFilterKey
    } = useAdvancedSearch();

    const activeCountryCriterion = getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0];
    const {
        data: segmentations,
        doFetch
    } = useSegmentations();

    const { t } = useLocale();

    const { doFetch: fetchRange } = useRanges();

    useEffect(() => {
        if (activeCountryCriterion?.value) {
            doFetch({
                country: activeCountryCriterion.value as string,
                locale,
            });
        }
    }, [activeCountryCriterion?.value, doFetch, locale]);

    const { data: countData } = useCountOperatorsQuery({
        variables: { filter: searchFilters as OperatorFilter },
        skip: !searchFilters,
    });

    const { data } = useGetAggregationsQuery({
        variables: { filter: searchFilters },
        skip: !searchFilters,
    });

    useEffect(() => {
        if (data?.aggregations) {
            setAggregations(
                new OperatorAggregations(
                    data.aggregations,
                    filterAndParseSegmentsChannels(segmentations?.children),
                    annualSalesRangeParams,
                    locale,
                    selectedOptionId === PERCENTS_ID,
                ),
            );
        }
    }, [data, locale, segmentations, annualSalesRangeParams, selectedOptionId]);

    useEffect(() => {
        if (activeCountryCriterion) {
            fetchRange({
                params: {
                    country: activeCountryCriterion.value,
                    locale,
                },
            }).then((res) => {
                if (res?.data?.ranges) {
                    Object.values(res.data.ranges).map((range) => {
                        const type = range?.type as RangesTypesEnum;
                        const criterionParamFilter = getCriterionParamFilterByRangeType(type);
                        const rangeParams = parseRanges(
                            range?.elements as RangeDTO[],
                            type,
                            locale,
                            activeCountryCriterion.value as CountryCodesEnum,
                            type !== RangesTypesEnum.AnnualSalesRangeType,
                        )?.map((range) =>
                            getRangeResponse(criterionParamFilter, range.id, range.title, range.min, range.max, locale),
                        );

                        switch (type) {
                            case RangesTypesEnum.ChainSizeRangeType:
                                setChainParams(rangeParams as CriterionParam[]);
                                break;
                            case RangesTypesEnum.AnnualSalesRangeType:
                                setAnnualSalesRangeParams(rangeParams as CriterionParam[]);
                                break;
                        }
                    });
                }
            });
        }
    }, [activeCountryCriterion, fetchRange, locale]);

    const onDrillDownSegments = useCallback(
        (segmentId: ID, segmentName: string) => {
            const segmentCriteria = getCriterionByFilterKey(CriterionEnum.Segments, CriterionParamFilter.Segment) ?? [];

            if (segmentCriteria?.length > 0) {
                const subChannel = segmentId ? segmentId.toString().split('.')[0] : '';
                const filteredSegments = segmentCriteria.filter((param) =>
                    param.value.toString().startsWith(segmentId.toString()),
                );
                const filteredSubChannel = segmentCriteria.filter((param) => param.value.toString() === subChannel);
                const mergedFilters = [...filteredSegments, ...filteredSubChannel];

                const activeCriteria = {
                    ...criteria,
                    [CriterionEnum.Segments]: {
                        ...CRITERIA[CriterionEnum.Segments],
                        CriterionParams:
                            filteredSubChannel.length > 0
                                ? [
                                    {
                                        value: segmentId,
                                        name: segmentName,
                                        filterKey: CriterionParamFilter.Segment,
                                        additionalData: undefined,
                                    },
                                ]
                                : mergedFilters,
                    },
                };

                localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(activeCriteria));

                return setTimeout(() => {
                    window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
                }, 200);
            }

            const mergedMockCriteria = {
                ...criteria,
                [CriterionEnum.Segments]: {
                    ...CRITERIA[CriterionEnum.Segments],
                    CriterionParams: [
                        {
                            value: segmentId,
                            name: segmentName,
                            filterKey: CriterionParamFilter.Segment,
                            additionalData: undefined,
                        },
                    ],
                },
            };

            const newCriteria = parseMockedCriteria(mergedMockCriteria);

            localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(newCriteria));

            return setTimeout(() => {
                window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
            }, 200);
        },
        [criteria, getCriterionByFilterKey],
    );

    const onDrillDownAnnualSalesRange = useCallback(
        (annualSalesRangeID: ID, annualSalesRangeName: string) => {
            const annualSalesRangeCriteria =
                getCriterionByFilterKey(CriterionEnum.General, CriterionParamFilter.AnnualSalesRange) ?? [];

            if (annualSalesRangeCriteria?.length > 0) {
                const activeCriteria = JSON.stringify(criteria);

                localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, activeCriteria);

                return setTimeout(() => {
                    window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
                }, 200);
            }

            const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];

            const mergedMockCriteria = {
                ...criteria,
                [CriterionEnum.General]: {
                    ...CRITERIA[CriterionEnum.General],
                    CriterionParams: [
                        ...allGeneralCriteria,
                        {
                            value: annualSalesRangeID,
                            name: `${t(OperatorProfilePhrasesEnum.EstimatedAnnualSales)}: ${annualSalesRangeName}`,
                            filterKey: CriterionParamFilter.AnnualSalesRange,
                            additionalData: undefined,
                        },
                    ],
                },
            };

            const newCriteria = parseMockedCriteria(mergedMockCriteria);

            localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(newCriteria));

            return setTimeout(() => {
                window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
            }, 200);
        },
        [criteria, getCriterionByFilterKey, t],
    );

    const onDrillDownChainsVsIndependents = useCallback(
        (id: ID) => {
            const isIndependents = id === INDEPENDENTS_ID;

            const chainSizeCriteria =
                getCriterionByFilterKey(CriterionEnum.General, CriterionParamFilter.ChainSizeRange) ?? [];

            if (chainSizeCriteria?.length > 0) {
                const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];
                const filteredGeneralCriteria = allGeneralCriteria.filter((criterion) => {
                    if (criterion.filterKey === CriterionParamFilter.ChainSizeRange) {
                        if (isIndependents) {
                            return criterion.value === INDEPENDENTS_CODE;
                        }

                        return criterion.value !== INDEPENDENTS_CODE;
                    }
                    return true;
                });
                const mergedMockCriteria: Partial<CriteriaData> = {
                    ...criteria,
                    [CriterionEnum.General]: {
                        ...CRITERIA[CriterionEnum.General],
                        CriterionParams: filteredGeneralCriteria,
                    },
                };

                const criteriaToBeStored = parseMockedCriteria(mergedMockCriteria);

                localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(criteriaToBeStored));

                return setTimeout(() => {
                    window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
                }, 200);
            }

            const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];

            const ChainSizeRangesToBeAdded = isIndependents
                ? chainParams.filter((range) => range.value === INDEPENDENTS_CODE)
                : chainParams.filter((range) => range.value !== INDEPENDENTS_CODE);

            const mergedMockCriteria: Partial<CriteriaData> = {
                ...criteria,
                [CriterionEnum.General]: {
                    ...CRITERIA[CriterionEnum.General],
                    CriterionParams: [...allGeneralCriteria, ...ChainSizeRangesToBeAdded],
                },
            };

            const criteriaToBeStored = parseMockedCriteria(mergedMockCriteria);

            localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(criteriaToBeStored));

            return setTimeout(() => {
                window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
            }, 200);
        },
        [chainParams, criteria, getCriterionByFilterKey],
    );

    const onDrillDownConfidenceLevel = useCallback(
        (level: ConfidenceLevel) => {
            const ConfidenceLevelCriteria =
                getCriterionByFilterKey(CriterionEnum.General, CriterionParamFilter.ConfidenceLevel) ?? [];

            if (ConfidenceLevelCriteria?.length > 0) {
                const activeCriteria = JSON.stringify(criteria);

                localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, activeCriteria);

                return setTimeout(() => {
                    window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
                }, 200);
            }

            const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];

            const mergedMockCriteria = {
                ...criteria,
                [CriterionEnum.General]: {
                    ...CRITERIA[CriterionEnum.General],
                    CriterionParams: [
                        ...allGeneralCriteria,
                        {
                            value: level,
                            name: `${t(OperatorProfilePhrasesEnum.ConfidenceLevel)}: ${capitalize(level)}`,
                            filterKey: CriterionParamFilter.ConfidenceLevel,
                            additionalData: undefined,
                        },
                    ],
                },
            };

            const newCriteria = parseMockedCriteria(mergedMockCriteria);

            localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(newCriteria));

            return setTimeout(() => {
                window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
            }, 200);
        },
        [criteria, getCriterionByFilterKey, t],
    );

    return {
        activeCountryCriterion,
        aggregations,
        resultsCount: countData?.count ?? 0,
        selectedOptionId,
        setSelectedOptionId,
        actions: {
            onDrillDownSegments,
            onDrillDownChainsVsIndependents,
            onDrillDownAnnualSalesRange,
            onDrillDownConfidenceLevel,
        },
    };
}

export default useGetOperatorSummaryData;

function parseMockedCriteria(mergedMockCriteria: Partial<CriteriaData>) {
    return Object.entries(mergedMockCriteria).reduce((acc, [key, value]) => {
        if (value.CriterionParams.length > 0) {
            acc[key as CriterionEnum] = value;
        }

        return acc;
    }, {} as CriteriaData);
}

function getRangeResponse(
    criterionParamFilter: CriterionParamFilter | null,
    rangeId: ID,
    title: string,
    min: number | undefined,
    max: number | undefined,
    locale: LocalesEnum,
) {
    return criterionParamFilter
        ? {
            value: rangeId,
            name: getRangeResponseTitleName(criterionParamFilter, title, locale),
            filterKey: criterionParamFilter,
            additionalData: {
                min: min,
                max: max,
                condensedLabel: title,
            },
        }
        : null;
}

function getRangeResponseTitleName(criterionParamFilter: CriterionParamFilter, title: string, locale: LocalesEnum) {
    switch (criterionParamFilter) {
        case CriterionParamFilter.AnnualSalesRange:
            return title ?? '';
        default:
            return buildDropdownOptionTitle(title, criterionParamFilter, locale);
    }
}

function getCriterionParamFilterByRangeType(type: RangesTypesEnum) {
    switch (type) {
        case RangesTypesEnum.ChainSizeRangeType:
            return CriterionParamFilter.ChainSizeRange;
        case RangesTypesEnum.AnnualSalesRangeType:
            return CriterionParamFilter.AnnualSalesRange;
        default:
            return null;
    }
}
