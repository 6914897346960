import { useCallback, useState } from 'react';
import cn from 'classnames';
import { Autocomplete, InputLabel, TextField, MenuItem, InputAdornment, AutocompleteProps } from '@mui/material';

import { FCX } from '@core/models';
import { useInput } from '@core/hooks/useInput';
import { IconClose, IconSearch } from '@assets/icons';
import { formatTestIds, replaceWhitespaceWithNonBreakingSpace, splitStringOnMatch } from '@core/utils/string';

import '../Inputs.styles.scss';
import './SearchInput.styles.scss';
import NoResultsFound from '../NoResultsFound';
import { SelectOption } from '../Inputs.helpers';

type SearchInputRenderOption = NonNullable<AutocompleteProps<SelectOption, false, false, false, 'div'>['renderOption']>;

const MIN_SEARCH_LENGTH = 2;

const SearchInput: FCX<{
    hasError?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    inputLabel?: string;
    options: SelectOption[];
    placeholder?: string;
    height?: string;
    value: SelectOption | null;
    onChangeCallback: (value: SelectOption | null) => void;
    onInputChangeCallback?: (value?: string) => void;
    highlightResults?: boolean;
    customWidth?: string;
    autocompleteProps?: Partial<AutocompleteProps<SelectOption, false, false, false, 'div'>>;
}> = ({
    hasError = false,
    isDisabled = false,
    isLoading = false,
    options = [],
    height,
    inputLabel,
    value,
    onChangeCallback,
    placeholder,
    highlightResults = false,
    testId,
    customWidth,
    onInputChangeCallback,
    autocompleteProps,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        onChange: onInputChange,
        value: inputValue
    } = useInput();

    const renderOption = useCallback<SearchInputRenderOption>(
        (props, option, { inputValue }) => {
            if (highlightResults) {
                const parts = splitStringOnMatch(option.label, inputValue);

                return (
                    <MenuItem
                        {...props}
                        key={option.id}
                        disableRipple
                        value={option.id}
                        data-testid={`searchInputOption${testId + formatTestIds(option.label)}`}
                    >
                        <div className="SearchInput__menu-item">
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    className={cn({
                                        SearchInput__highlight: part.isMatch,
                                    })}
                                >
                                    {replaceWhitespaceWithNonBreakingSpace(part.text)}
                                </span>
                            ))}
                        </div>
                    </MenuItem>
                );
            }

            return (
                <MenuItem
                    {...props}
                    key={option.id}
                    disableRipple
                    value={option.id}
                    data-testid={`searchInputOption${testId + formatTestIds(option.label)}`}
                >
                    {option.label}
                </MenuItem>
            );
        },
        [highlightResults, testId],
    );

    return (
        <div
            className="SearchInput"
            style={{
                ...(customWidth && { width: customWidth }),
            }}
        >
            <Autocomplete
                value={value}
                onChange={(_, newValue) => {
                    onChangeCallback(newValue);
                    setIsOpen(false);
                }}
                inputValue={inputValue ?? ''}
                onInputChange={(_e, newInputValue) => {
                    onInputChange(newInputValue);
                    onInputChangeCallback?.(newInputValue);
                    setIsOpen(newInputValue.length >= MIN_SEARCH_LENGTH);
                }}
                open={isOpen && !isLoading}
                fullWidth
                onClose={() => {
                    setIsOpen(false);
                }}
                options={options}
                getOptionLabel={(option) => option.label}
                noOptionsText={<NoResultsFound />}
                classes={{
                    option: 'Inputs__option',
                    listbox: 'Inputs__options-list',
                    popper: 'Inputs__popper',
                    paper: 'Inputs__dropdown',
                }}
                forcePopupIcon={false}
                getOptionDisabled={(option) => option?.isDisabled ?? false}
                renderInput={(params) => (
                    <>
                        {!!inputLabel && <InputLabel error={hasError}>{inputLabel}</InputLabel>}
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        disablePointerEvents={true}
                                        sx={{ marginLeft: '5px' }}
                                    >
                                        <IconSearch />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        className={cn('Inputs__clear-icon', {
                                            'Inputs__clear-icon--visible':
                                                inputValue && inputValue?.length >= MIN_SEARCH_LENGTH,
                                        })}
                                        position="end"
                                        onClick={() => {
                                            onInputChange('');
                                            setIsOpen(false);
                                            onChangeCallback(null);
                                        }}
                                    >
                                        {isLoading ? (
                                            <div className="Inputs__loader" />
                                        ) : (
                                            <IconClose data-testid="InputClearIcon" />
                                        )}
                                    </InputAdornment>
                                ),
                                sx: {
                                    height: height ?? 'auto',
                                },
                            }}
                            onFocus={() => {
                                if (inputValue && inputValue?.length >= MIN_SEARCH_LENGTH && value === null) {
                                    setIsOpen(true);
                                }
                            }}
                            placeholder={placeholder}
                            variant="outlined"
                            disabled={isDisabled}
                            error={hasError}
                            data-testid={`searchInput${formatTestIds(testId)}`}
                        />
                    </>
                )}
                renderOption={renderOption}
                {...autocompleteProps}
            />
        </div>
    );
};

export default SearchInput;
