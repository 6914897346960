import { FC, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { LocalesEnum } from '@core/enums/localeEnum';

import './Pagination.styles.scss';
import Select from '../Inputs/Select/Select';
import { SelectOption } from '../Inputs/Inputs.helpers';
import NumberAnimation from '../NumberAnimation/NumberAnimation';

export const DEFAULT_SELECT_OPTIONS: SelectOption[] = [
    { label: '10', id: 10 },
    { label: '20', id: 20 },
    { label: '50', id: 50 },
    { label: '100', id: 100 },
];

const PaginationSelect: FC<{
    perPage: number;
    totalResultsCount: number;
    onChangePerPage: (value: number) => void;
}> = ({
    totalResultsCount,
    perPage,
    onChangePerPage
}) => {
    const { locale } = useLocale();
    const selectInputRender = useMemo(
        () => (
            <div className="PaginationSelect__select">
                <Select
                    value={`${perPage}`}
                    onChange={(value) => {
                        if (value) {
                            onChangePerPage(Number(value));
                        }
                    }}
                    options={DEFAULT_SELECT_OPTIONS}
                    testId="dropdownNumberOfShowingResults"
                />
            </div>
        ),
        [perPage, onChangePerPage],
    );

    const totalCountRender = useMemo(
        () => (
            <strong data-testid="labelNumberOfResults" style={{ display: 'flex' }}>
                <NumberAnimation count={totalResultsCount} />
                &nbsp;
            </strong>
        ),
        [totalResultsCount],
    );

    const contentRender = useMemo(() => {
        switch (locale) {
            case LocalesEnum.Fr:
                return (
                    <>
                        Résultats&nbsp;{selectInputRender}sur&nbsp;{totalCountRender}
                    </>
                );
            default:
                return (
                    <>
                        Showing&nbsp;{selectInputRender}of&nbsp;{totalCountRender}&nbsp;results
                    </>
                );
        }
    }, [locale, selectInputRender, totalCountRender]);

    return <div className="PaginationSelect">{contentRender}</div>;
};

export default PaginationSelect;
