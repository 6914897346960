import { FC, useEffect, useMemo } from 'react';

import { IconFilterTags } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { FilterTypeEnum } from '@enums/localeEnum';
import { TagsCategoryEnum } from '@core/api/useTags';
import { GradientColorsEnum } from '@enums/gradientEnum';
import Heading from '@components/ui/Typography/Heading';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import useTagsCriteria from '@pages/OperatorTargetingCriteria/hooks/useTagsCriteria';
import { getSafeBottomMargin } from '@pages/OperatorTargetingCriteria/helpers/Criteria.helpers';
import useListenToActiveMenuItem from '@pages/OperatorTargetingCriteria/hooks/useListenToActiveMenuItem';

import './TagsContent.styles.scss';
import TagsCategoryList from './TagsCategoryList';

const TagsContent: FC<{
    onUpdateActiveNavigationSubItem: (id: string) => void;
}> = ({ onUpdateActiveNavigationSubItem }) => {
    const { t } = useLocale();
    const { tags } = useTagsCriteria();

    const { observe } = useListenToActiveMenuItem(
        onUpdateActiveNavigationSubItem,
        '[data-observable]',
        '#observer-root',
    );

    const safeBottomMargin = useMemo(() => getSafeBottomMargin(), []);

    useEffect(() => {
        setTimeout(() => {
            observe();
        }, 1500);
    }, [observe]);

    return (
        <div className="CriteriaContent TagsContent" style={{ marginBottom: safeBottomMargin }}>
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterTags />} baseColor={GradientColorsEnum.ORANGE} />
                <Heading>{t(FilterTypeEnum.Tags)}</Heading>
            </div>

            {tags?.[TagsCategoryEnum.FOOD] && <TagsCategoryList tagsData={tags[TagsCategoryEnum.FOOD]} />}

            {tags?.[TagsCategoryEnum.PLACES] && <TagsCategoryList tagsData={tags[TagsCategoryEnum.PLACES]} />}
        </div>
    );
};

export default TagsContent;
