import { useState } from 'react';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_INITIAL_PAGE = 1;

function usePagination(pageSize = DEFAULT_PAGE_SIZE, initialPage = DEFAULT_INITIAL_PAGE) {
    const [perPage, setPerPage] = useState(pageSize);
    const [activePage, setActivePage] = useState(initialPage);

    const onResetPagination = () => {
        setActivePage(initialPage);
        setPerPage(pageSize);
    };

    return {
        perPage,
        onChangePerPage: (amountPerPage: number) => {
            setPerPage(amountPerPage);
        },
        activePage,
        onChangePage: (page: number) => {
            setActivePage(page);
        },
        onResetPagination,
    };
}

export default usePagination;
