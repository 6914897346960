import { components, paths } from '@core/api/api';
import useFetch from '@hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_RANGES = '/ranges';

type GetRangesEndpoint = paths[typeof GET_RANGES][MethodsEnum.GET];

export type RangesParams = Omit<GetRangesEndpoint[OperationsEnum.PARAMETERS]['query'], 'type'> & {
    type?: RangesTypesEnum;
};

export type RangesResponse = GetRangesEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];

export type RangeDTO = components['schemas']['RangeDTO'];

export enum RangesTypesEnum {
    ChainSizeRangeType = 'CHAIN_SIZE_RANGE',
    YearsInBusinessRangeType = 'YEARS_IN_BUSINESS_RANGE',
    NumberOfEmployeesCommercialRangeType = 'NUMBER_OF_EMPLOYEES_COMMERCIAL_RANGE',
    NumberOfEmployeesNonCommercialRangeType = 'NUMBER_OF_EMPLOYEES_NONCOMMERCIAL_RANGE',
    AnnualSalesRangeType = 'ANNUAL_SALES_RANGE',
    PurchasePotentialTotalRangeType = 'PURCHASE_POTENTIAL_TOTAL_RANGE',
    EstMealsPerDayRangeType = 'ESTIMATED_MEALS_PER_DAY_RANGE',
    NumberOfRoomsRangeType = 'NUMBER_OF_ROOMS_RANGE',
    ConfidenceLevelRangeType = 'CONFIDENCE_LEVEL_RANGE',
    HotelClassRangeType = 'HOTEL_CLASS_RANGE',
}

export default function useRanges(params?: RangesParams) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<RangesResponse>({
        url: GET_RANGES,
        lazy: true,
        initialConfig: {
            params,
        },
    });

    return { data, loading, error, doFetch } as const;
}
