import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

import TabPanel from './TabPanel';
import './Tabs.styles.scss';

export type TabsContent = {
    label: ReactNode;
    title?: string;
    content: ReactNode;
    onClick?: () => void;
};

export enum TabsVariant {
    Primary = 'primary',
    Scrollable = 'scrollable',
}

const Tabs: FC<{
    tabsContent: TabsContent[];
    variant?: TabsVariant;
    hasSharedContent?: boolean;
    maxHeight?: string;
    MuiTabsProps?: TabsProps;
}> = ({
    tabsContent,
    hasSharedContent,
    MuiTabsProps,
    variant = TabsVariant.Primary,
    maxHeight
}) => {
    const [value, setValue] = useState(0);

    if (!tabsContent || !tabsContent.length) return null;

    return (
        <div
            className={cn('Tabs', { 'Tabs--scrollable': variant === TabsVariant.Scrollable })}
            data-testid="tabs-component"
        >
            <MuiTabs
                value={value}
                onChange={(_event, newValue) => setValue(newValue)}
                className="MuiTabs"
                classes={{
                    root: 'MuiTabs__root',
                    flexContainer: 'MuiTabs__flex-container',
                    indicator: 'MuiTabs__indicator',
                }}
                textColor="primary"
                {...MuiTabsProps}
            >
                {tabsContent.map((tabContent, index) => (
                    <MuiTab
                        onClick={() => tabContent.onClick?.()}
                        className="MuiTab"
                        classes={{
                            textColorPrimary: 'MuiTab__button',
                            selected: 'MuiTab__selected',
                        }}
                        key={index}
                        label={tabContent.label}
                        disableRipple
                        {...(tabContent.title && {
                            'data-testid': `TabButton${tabContent.title}`,
                        })}
                    />
                ))}
            </MuiTabs>
            <div className="Tabs__wrapper" style={{ maxHeight }}>
                {tabsContent.map((tabContent, index) => (
                    <TabPanel key={index} value={value} index={index} hasSharedContent={hasSharedContent}>
                        {tabContent.content}
                    </TabPanel>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
