import { FC, useMemo } from 'react';

import {
    CommonPhrasesEnum,
    FilterTypeEnum,
    HomePhrasesEnum,
    OperatorTargetingResultsPhrasesEnum,
    OperatorProfilePhrasesEnum,
} from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import { getCurrencyCode } from '@core/utils/currency';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { formatNumberByLocale } from '@core/utils/string';
import Collapse from '@components/utility/Collapse/Collapse';
import { IconFoamHand, IconPercentage } from '@assets/icons';
import { WidgetType } from '@components/utility/Widget/Widget';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { NumberAnimation } from '@components/utility/NumberAnimation/NumberAnimation';
import GroupedBarChart from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import ToggleButton, { ToggleOption } from '@components/utility/ToggleButton/ToggleButton';
import DonutChart, { GradientStyles } from '@components/utility/Charts/DonutChart/DonutChart';
import { useLocationCriteria } from '@pages/OperatorTargetingCriteria/hooks/useLocationCriteria';
import ComparisonBarChart from '@components/utility/Charts/ComparisonBarChart/ComparisonBarChart';
import { COUNTS_ID, PERCENTS_ID } from '@pages/OperatorTargetingResults/hooks/useGetOperatorSummaryData';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';

import './OperatorResultsSummary.styles.scss';
import ConfidenceLevelChartLegend from './ConfidenceLevelChartLegend';
import { ConfidenceLevel } from '@graphql/generated/graphql';
import GroupedVerticalProgressBar from '@components/utility/Charts/GroupedVerticalProgressBar/GroupedVerticalProgressBar';

const CONFIDENCE_LEVEL_CHART_STYLES: GradientStyles = {
    gradientHIGH: { startColor: '#07C7F9', endColor: '#1DFEEC' },
    gradientMEDIUM: { startColor: '#00B4FF', endColor: '#07C7F9' },
    gradientLOW: { startColor: '#006EF5', endColor: '#00B4FF' },
};

const OperatorResultsSummary: FC = () => {
    const { selectedCountry } = useLocationCriteria();
    const isAnnualSalesRangeEnabled = useFeatureFlag(FeatureFlags.AnnualSalesRange);
    const isConfidenceLevelChartEnabled = useFeatureFlag(FeatureFlags.ConfidenceLevelChart);

    const {
        t,
        locale
    } = useLocale();

    const {
        summaryData: {
            aggregations,
            resultsCount,
            selectedOptionId,
            setSelectedOptionId,
            actions: {
                onDrillDownSegments,
                onDrillDownAnnualSalesRange,
                onDrillDownChainsVsIndependents,
                onDrillDownConfidenceLevel,
            },
        },
    } = useOperatorTargetingResultsContext();

    const toggleButtonOptions: [ToggleOption, ToggleOption] = useMemo(() => {
        return [
            { id: COUNTS_ID, title: t(OperatorTargetingResultsPhrasesEnum.Counts), icon: <IconFoamHand /> },
            { id: PERCENTS_ID, title: t(OperatorTargetingResultsPhrasesEnum.Percents), icon: <IconPercentage /> },
        ];
    }, [t]);

    return (
        <div className="OperatorResultsSummary">
            <div className="OperatorResultsTab">
                <div className="OperatorResultsTab__content">
                    <ToggleButton
                        options={toggleButtonOptions}
                        selectedOptionId={selectedOptionId}
                        onToggle={setSelectedOptionId}
                        testId="CountsPercents"
                    />
                </div>
                <div className="OperatorResultsTab__results-count" data-testid="tableResultsCount">
                    <NumberAnimation className="OperatorResultsTab__results-count-numbers" count={resultsCount} />
                    {t(CommonPhrasesEnum.Results)}
                </div>
            </div>
            <Collapse
                title={
                    <Heading variant={HeadingVariant.H3}>
                        {t(HomePhrasesEnum.All)} ({formatNumberByLocale(resultsCount, locale)})
                    </Heading>
                }
                startCollapsed={false}
                widgetProps={{
                    type: WidgetType.Section,
                    className: 'section-small',
                }}
            >
                <div className="OperatorResultsSummary__charts">
                    {aggregations?.segments && (
                        <div className="OperatorResultsSummary__segments">
                            <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Segments)}</Heading>
                            <GroupedBarChart groups={aggregations.segments} onBarClick={onDrillDownSegments} />
                        </div>
                    )}
                    {aggregations?.chainsVsIndependents && (
                        <div className="OperatorResultsSummary__units-comparison">
                            <Heading variant={HeadingVariant.H4}>
                                {t(OperatorTargetingResultsPhrasesEnum.ChainsVsIndependents)}
                            </Heading>
                            <ComparisonBarChart
                                data={aggregations.chainsVsIndependents}
                                showPercents={selectedOptionId === PERCENTS_ID}
                                onBarClick={onDrillDownChainsVsIndependents}
                            />
                        </div>
                    )}
                </div>

                <div className="OperatorResultsSummary__charts">
                    {isConfidenceLevelChartEnabled && (
                        <div className="OperatorResultsSummary__donut-charts">
                            <Heading variant={HeadingVariant.H4}>
                                {t(OperatorProfilePhrasesEnum.ConfidenceLevel)}
                            </Heading>
                            <div className="OperatorResultsSummary__chart-legend-wrapper">
                                <ConfidenceLevelChartLegend
                                    aggregations={aggregations?.confidenceLevel}
                                    showPercents={selectedOptionId === PERCENTS_ID}
                                    locale={locale}
                                    styles={CONFIDENCE_LEVEL_CHART_STYLES}
                                />
                                <DonutChart
                                    data={(aggregations?.confidenceLevel || []).map((item, index) => ({
                                        id: index,
                                        value: item.value,
                                        percent: item.percent,
                                        color: `gradient${item.id?.toString()}`,
                                        label: item.id?.toString() ?? '',
                                        onClickCb: () =>
                                            onDrillDownConfidenceLevel(item.id.toString() as ConfidenceLevel),
                                    }))}
                                    styles={CONFIDENCE_LEVEL_CHART_STYLES}
                                />
                            </div>
                        </div>
                    )}

                    {isAnnualSalesRangeEnabled &&
                        (aggregations?.annualSalesRange ?? []).every(
                            (item) => item.value === '0' || item.value === '0.0%',
                        ) === false && (
                        <div className="OperatorResultsSummary__annual-sales">
                            <Heading variant={HeadingVariant.H4}>
                                {t(OperatorProfilePhrasesEnum.EstimatedAnnualSales)} (
                                {getCurrencyCode(selectedCountry?.value as CountryCodesEnum)})
                            </Heading>

                            <GroupedVerticalProgressBar
                                data={aggregations.annualSalesRange || []}
                                onBarClick={onDrillDownAnnualSalesRange}
                            />
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    );
};

export default OperatorResultsSummary;
