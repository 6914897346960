import { useEffect, useMemo, useState } from 'react';

import { isEmpty } from '@core/helpers/helpers';
import { OperatorFilterFactory } from '@core/models/OperatorFilter';
import { OperatorSegmentsFilter } from '@core/models/OperatorSegmentsFilter';
import { Maybe, OperatorFilter, OperatorType, StringExpression } from '@graphql/generated/graphql';
import { CriteriaData, CriterionParamFilter, NoEmployeesCountDataEnum } from '@core/models/Criterion';

/** This is the default operator type that will always be present in the search filters. */
const DEFAULT_OPERATOR_TYPE: OperatorFilter = { type: [OperatorType.Unit] };

function useAdvancedSearchFilters(criteria: CriteriaData | undefined) {
    const [filters, setFilters] = useState<OperatorFilter[]>([]);

    useEffect(() => {
        if (criteria !== undefined) {
            const operatorFilters = OperatorFilterFactory.buildFilters(criteria);
            const withsegmentsProcessed = OperatorSegmentsFilter.proccessSegments(operatorFilters);
            const withNumberOfEmployeesProcessed = processNumberOfEmployeesCustomFilters(withsegmentsProcessed);
            const withTagsProcessed = processTagsCustomFilters(withNumberOfEmployeesProcessed);

            setFilters(withTagsProcessed);
        }
    }, [criteria]);

    const searchFilters = useMemo(() => {
        if (filters.length === 0) {
            return undefined;
        }

        return { and: [DEFAULT_OPERATOR_TYPE, ...filters] };
    }, [filters]);

    return { searchFilters };
}

function processTagsCustomFilters(filters: OperatorFilter[]): OperatorFilter[] {
    const foodTags = OperatorSegmentsFilter.getOperatorFilterByKey(filters, CriterionParamFilter.FoodTags)?.[
        CriterionParamFilter.FoodTags as keyof OperatorFilter
    ] as StringExpression;

    const placeTags = OperatorSegmentsFilter.getOperatorFilterByKey(filters, CriterionParamFilter.PlaceTags)?.[
        CriterionParamFilter.PlaceTags as keyof OperatorFilter
    ] as StringExpression;

    if (!foodTags && !placeTags) {
        return filters;
    }

    const filtersWithoutTags = filters.filter((filter) => {
        return !(CriterionParamFilter.FoodTags in filter || CriterionParamFilter.PlaceTags in filter);
    });

    const combinedTagsFilter = {
        or: [...(foodTags ? [{ tags: foodTags }] : []), ...(placeTags ? [{ tags: placeTags }] : [])],
    };

    return [...filtersWithoutTags, combinedTagsFilter];
}

const NonCommercialCodeMatch = 'S04.%';
const NoEmployeesDataCodesMatch = 'R02.%9%';
const COMMERCIAL_KEY = CriterionParamFilter.NumberOfEmployeesRangeCommercial;
const NON_COMMERCIAL_KEY = CriterionParamFilter.NumberOfEmployeesRangeNonCommercial;
const NO_EMPLOYEES_FILTERS = {
    commercial: {
        and: [
            { number_of_employees_range: { contains: NoEmployeesDataCodesMatch } },
            { not: { segmentation: { contains: NonCommercialCodeMatch } } },
        ],
    },
    nonCommercial: {
        and: [
            { number_of_employees_range: { contains: NoEmployeesDataCodesMatch } },
            { segmentation: { contains: NonCommercialCodeMatch } },
        ],
    },
    both: { number_of_employees_range: { contains: NoEmployeesDataCodesMatch } },
};

/**
 * This function processes the NumberOfEmployeesRange filters by pasring to the correct api filter key and handling whenever the user filters for 'No Count Data' on commercial or non-commercial.
 * The function will return the original filters if none of the NumberOfEmployeesRange filter keys are present.
 *
 * @param filters OperatorFilter[]
 * @returns OperatorFilter[] with the NumberOfEmployeesRange filters processed.
 */
function processNumberOfEmployeesCustomFilters(filters: OperatorFilter[]): OperatorFilter[] {
    const commercialEmployees = OperatorSegmentsFilter.getOperatorFilterByKey(filters, COMMERCIAL_KEY);
    const nonCommercialEmployees = OperatorSegmentsFilter.getOperatorFilterByKey(filters, NON_COMMERCIAL_KEY);

    if (!commercialEmployees && !nonCommercialEmployees) {
        return filters;
    }

    const [isNoDataCommercialActive, parsedCommercialEmployees] = parseNumberOfEmployees(
        commercialEmployees,
        COMMERCIAL_KEY,
    );

    const [isNoDataNonCommercialActive, parsedNonCommercialEmployees] = parseNumberOfEmployees(
        nonCommercialEmployees,
        NON_COMMERCIAL_KEY,
    );

    const filtersWithoutNumberOfEmployees = filters.filter((filter) => {
        return !(COMMERCIAL_KEY in filter || NON_COMMERCIAL_KEY in filter);
    });

    if (isNoDataCommercialActive && isNoDataNonCommercialActive) {
        return [
            ...filtersWithoutNumberOfEmployees,
            {
                or: [
                    NO_EMPLOYEES_FILTERS.both,
                    ...(parsedCommercialEmployees ? [parsedCommercialEmployees] : []),
                    ...(parsedNonCommercialEmployees ? [parsedNonCommercialEmployees] : []),
                ],
            },
        ];
    }

    return [
        ...filtersWithoutNumberOfEmployees,
        {
            or: [
                ...(isNoDataCommercialActive ? [NO_EMPLOYEES_FILTERS.commercial] : []),
                ...(isNoDataNonCommercialActive ? [NO_EMPLOYEES_FILTERS.nonCommercial] : []),
                ...(parsedCommercialEmployees ? [parsedCommercialEmployees] : []),
                ...(parsedNonCommercialEmployees ? [parsedNonCommercialEmployees] : []),
            ],
        },
    ];
}

function parseNumberOfEmployees(
    employeesFilter: OperatorFilter | undefined,
    filterKey:
        | CriterionParamFilter.NumberOfEmployeesRangeCommercial
        | CriterionParamFilter.NumberOfEmployeesRangeNonCommercial,
): [boolean, OperatorFilter | undefined] {
    let IsNoEmployeeCountDataActive = false;
    const noEmployeesCountDataEnum =
        filterKey === CriterionParamFilter.NumberOfEmployeesRangeCommercial
            ? NoEmployeesCountDataEnum.Commercial
            : NoEmployeesCountDataEnum.NonCommercial;

    const parsedValues: StringExpression = Object.entries(
        employeesFilter?.[filterKey as keyof OperatorFilter] || {},
    ).reduce(
        (acc, [key, values]: [string, string[] | string]) => {
            if (!Array.isArray(values)) {
                values = [values];
            }

            const isNoEmployeeCountDataIncluded = values?.includes(noEmployeesCountDataEnum);

            if (isNoEmployeeCountDataIncluded) {
                const newValues = values?.filter((currVal: string) => currVal !== noEmployeesCountDataEnum);

                if (newValues.length > 0) {
                    IsNoEmployeeCountDataActive = true;
                    return { ...acc, [key]: newValues };
                }

                return acc;
            }

            return {
                ...acc,
                [key]: values,
            };
        },
        {} as Record<string, string[]>,
    );

    const parsedNumberOfEmployeesExpression: Maybe<OperatorFilter> = isEmpty(parsedValues)
        ? undefined
        : {
            number_of_employees_range: parsedValues,
        };

    return [IsNoEmployeeCountDataActive, parsedNumberOfEmployeesExpression];
}

export default useAdvancedSearchFilters;
