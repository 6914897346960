import { useCallback } from 'react';

import { paths } from '@core/api/api';
import useFetch, { Config } from '@hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_SEGMENTATIONS = '/segmentations';

type GetSegmentationsEndpoint = paths[typeof GET_SEGMENTATIONS][MethodsEnum.GET];
type SegmentationsParams = GetSegmentationsEndpoint[OperationsEnum.PARAMETERS]['query'];
export type SegmentationsResponse = GetSegmentationsEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];
export enum SegmentationsLevelEnum {
    Channel = 'CHANNEL',
    SubChannel = 'SUBCHANNEL',
    Segment = 'SEGMENT',
}

export default function useSegmentations(params?: SegmentationsParams) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<SegmentationsResponse>({
        url: GET_SEGMENTATIONS,
        lazy: true,
        initialConfig: {
            params,
        },
    });

    const doFetchWithParams = useCallback(
        (queryParams?: SegmentationsParams, newConfig?: Config) => {
            doFetch({
                params: queryParams,
                ...newConfig,
            });
        },
        [doFetch],
    );

    return { data, loading, error, doFetch: doFetchWithParams } as const;
}
