/* eslint-disable camelcase */
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import { SwitchListOption } from '@components/utility/SwitchList/SwitchList';
import { RangeDTO, RangesResponse, RangesTypesEnum } from '@core/api/useRanges';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { CriterionParamFilter } from '@core/models/Criterion';
import { formatPrice, getCurrencyCode } from '@core/utils/currency';
import {
    Dictionary,
    FilterTypeEnum,
    LocalesEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    RangeFiltersEnum,
} from '@enums/localeEnum';

export type ParsedRangeResponse = Omit<RangesResponse, 'ranges'> & {
    ranges: SwitchListOption[];
};

export type RangesData = {
    [key in RangesTypesEnum]: ParsedRangeResponse;
};

type TitlePreposition = {
    more: string;
    less: string;
    to: string;
};

function buildRangeTitle(
    min?: number,
    max?: number,
    unit = '',
    prefix = '',
    preposition?: TitlePreposition,
    parsedValues?: {
        min?: string | number;
        max?: string | number;
    },
): string | null {
    if (!min && !max) {
        return null;
    }

    if (min && !max) {
        return `${prefix} ${preposition?.more ?? ''} ${parsedValues?.min} ${unit}`;
    }

    if (!min && max) {
        return `${prefix} ${preposition?.less ?? ''} ${parsedValues?.max} ${unit}`;
    }

    return `${prefix} ${parsedValues?.min} ${preposition?.to ?? ''} ${parsedValues?.max} ${unit}`;
}

function switchPreposition(rangeType: RangesTypesEnum, locale: LocalesEnum) {
    switch (rangeType) {
        case RangesTypesEnum.PurchasePotentialTotalRangeType:
        case RangesTypesEnum.AnnualSalesRangeType:
            return {
                more: Dictionary[RangeFiltersEnum.MoreThan][locale],
                less: Dictionary[RangeFiltersEnum.LessThan][locale],
                to: '-',
            };
        default:
            return {
                more: Dictionary[RangeFiltersEnum.MoreThan][locale],
                less: Dictionary[RangeFiltersEnum.LessThan][locale],
                to: Dictionary[RangeFiltersEnum.To][locale],
            };
    }
}

function switchCustomTitle(rangeType: RangesTypesEnum, locale: LocalesEnum) {
    switch (rangeType) {
        case RangesTypesEnum.ChainSizeRangeType:
            return Dictionary[RangeFiltersEnum.Independents][locale];
        default:
            return '';
    }
}

function switchPrefix(rangeType: RangesTypesEnum, locale: LocalesEnum) {
    switch (rangeType) {
        case RangesTypesEnum.ChainSizeRangeType:
            return Dictionary[RangeFiltersEnum.Chains][locale] + ':';
        default:
            return undefined;
    }
}

function switchUnit(rangeType: RangesTypesEnum, locale: LocalesEnum, countryCode: CountryCodesEnum, isPlural = false) {
    switch (rangeType) {
        case RangesTypesEnum.ChainSizeRangeType:
            return Dictionary[OperatorProfilePhrasesEnum.Units][locale];

        case RangesTypesEnum.YearsInBusinessRangeType:
            if (isPlural) {
                return Dictionary[RangeFiltersEnum.Years][locale];
            }
            return Dictionary[RangeFiltersEnum.Year][locale];

        case RangesTypesEnum.PurchasePotentialTotalRangeType:
        case RangesTypesEnum.AnnualSalesRangeType:
            return getCurrencyCode(countryCode);

        default:
            return undefined;
    }
}

function switchValue(
    rangeType: RangesTypesEnum,
    locale: LocalesEnum,
    countryCode: CountryCodesEnum,
    min?: number,
    max?: number,
) {
    switch (rangeType) {
        case RangesTypesEnum.PurchasePotentialTotalRangeType:
        case RangesTypesEnum.AnnualSalesRangeType:
            return {
                min: formatPrice(countryCode, min ?? 0, locale),
                max: formatPrice(countryCode, max ?? 0, locale),
            };

        default:
            return {
                min: min,
                max: max,
            };
    }
}
function parseMinMaxValues(rangeType: RangesTypesEnum, min?: number, max?: number) {
    if (rangeType === RangesTypesEnum.YearsInBusinessRangeType) {
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        const todayDay = today.getDate();
        let minDate, maxDate;

        if (min) {
            minDate = new Date(todayYear - min, todayMonth, todayDay);
        }

        if (max) {
            maxDate = new Date(todayYear - max, todayMonth, todayDay);
        }

        return {
            min: minDate?.toISOString().split('T')[0],
            max: maxDate?.toISOString().split('T')[0],
        };
    }

    return { min, max };
}

export function serializeRangeCodes(rangeCodes?: string[]) {
    return rangeCodes?.join(',') ?? '';
}

export function parseRanges(
    ranges: RangeDTO[],
    rangeType: RangesTypesEnum,
    locale: LocalesEnum,
    countryCode: CountryCodesEnum,
    usesUnit = true,
): SwitchListOption[] {
    const parsedRanges =
        ranges
            ?.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
            .map((range) => {
                const {
                    display_min: displayMin,
                    display_max: displayMax,
                    range_codes: rangeCodes,
                    title_message_code: titleMessageCode,
                } = range;

                const prefix = switchPrefix(rangeType, locale);
                const unit = usesUnit ? switchUnit(rangeType, locale, countryCode, displayMax !== 1) : undefined;
                const preposition = switchPreposition(rangeType, locale);
                const parsedValues = switchValue(rangeType, locale, countryCode, displayMin, displayMax);
                const {
                    min,
                    max
                } = parseMinMaxValues(rangeType, displayMin, displayMax);
                const formattedTitle = buildRangeTitle(displayMin, displayMax, unit, prefix, preposition, parsedValues);

                const title = titleMessageCode ?? formattedTitle ?? switchCustomTitle(rangeType, locale);
                const subtitle = titleMessageCode ? (formattedTitle ?? '') : undefined;

                return {
                    title: title.trim(),
                    formattedTitle,
                    subtitle,
                    id: serializeRangeCodes(rangeCodes ?? [String(displayMin), String(displayMax)]),
                    min,
                    max,
                } as SwitchListOption;
            }) ?? [];

    return parsedRanges;
}

export function mapRangeToDropdownOption(
    range: SwitchListOption,
    rangeType: CriterionParamFilter,
    locale: LocalesEnum,
): DropdownOption {
    return {
        id: range.id,
        label: buildDropdownOptionTitle(range.title, rangeType, locale),
        value: range.id as string,
        additionalData: {
            min: range.min,
            max: range.max,
            condensedLabel: range.title,
        },
    };
}

export function buildDropdownOptionTitle(
    title: string,
    rangeType: CriterionParamFilter,
    locale = LocalesEnum.En,
): string {
    if (title.includes(':')) {
        return title;
    }

    switch (rangeType) {
        case CriterionParamFilter.ChainSizeRange:
            return `${title}`;
        case CriterionParamFilter.YearsInBusinessRange:
            return `${Dictionary[OperatorProfilePhrasesEnum.YearsinBusiness][locale]}: ${title}`;
        case CriterionParamFilter.AnnualSalesRange:
            return `${Dictionary[OperatorProfilePhrasesEnum.EstimatedAnnualSales][locale]}: ${title}`;
        case CriterionParamFilter.PotentialPurchaseTotal:
            return `${Dictionary[FilterTypeEnum.TotalPurchasePotential][locale]}: ${title}`;
        case CriterionParamFilter.NumberOfEmployeesRangeCommercial:
            return `${Dictionary[OperatorTargetingPhrasesEnum.CommercialEmployees][locale]}: ${title}`;
        case CriterionParamFilter.NumberOfEmployeesRangeNonCommercial:
            return `${Dictionary[OperatorTargetingPhrasesEnum.NonCommercialEmployees][locale]}: ${title}`;
        case CriterionParamFilter.ConfidenceLevel:
            return `${Dictionary[OperatorProfilePhrasesEnum.ConfidenceLevel][locale]}: ${title}`;
        case CriterionParamFilter.EstMealsPerDayRange:
            return `${Dictionary[OperatorProfilePhrasesEnum.EstMealsPerDay][locale]}: ${title}`;
        case CriterionParamFilter.NumberOfRoomsRange:
            return `${Dictionary[FilterTypeEnum.NumberofRooms][locale]}: ${title}`;
        case CriterionParamFilter.HotelStarLevel:
            return `${Dictionary[OperatorProfilePhrasesEnum.HotelClass][locale]}: ${title}`;
        default:
            return '';
    }
}
