import { localizeNumber } from '@core/utils/currency';
import { getPercentageString } from '@core/helpers/helpers';
import { SegmentationsLevelEnum } from '@core/api/useSegmentation';
import { BarChartGroup, BarData } from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import { ClassificationDTO } from '@pages/OperatorTargetingCriteria/helpers/Classification.helpers';
import { Dictionary, LocalesEnum, OperatorTargetingResultsPhrasesEnum } from '@core/enums/localeEnum';
import { ComparisonChartData } from '@components/utility/Charts/ComparisonBarChart/ComparisonBarChart';
import { OperatorSearchAggregation as ApiOperatorAggregationsModel } from '@graphql/generated/graphql';
import { VerticalProgressBarData } from '@components/utility/Charts/VerticalProgressBar/VerticalProgressBar';
import { CriterionParam } from './Criterion';
import { RangesTypesEnum } from '@core/api/useRanges';
import { DonutSlice } from '@components/utility/Charts/DonutChart/DonutChart';

export type OperatorAggregationsModel = {
    chainsVsIndependents: [ComparisonChartData, ComparisonChartData] | null;
    segments?: BarChartGroup[] | null;
    annualSalesRange?: VerticalProgressBarData[] | null;
    confidenceLevel?: DonutSlice[] | null;
};

export class OperatorAggregations implements OperatorAggregationsModel {
    static defaultData: OperatorAggregationsModel = {
        chainsVsIndependents: null,
        segments: null,
        annualSalesRange: null,
        confidenceLevel: null,
    };

    locale = LocalesEnum.En;
    showPercents = false;
    chainsVsIndependents = OperatorAggregations.defaultData.chainsVsIndependents;
    segments = OperatorAggregations.defaultData.segments;
    annualSalesRange = OperatorAggregations.defaultData.annualSalesRange;
    confidenceLevel = OperatorAggregations.defaultData.confidenceLevel;

    constructor(
        apiData?: ApiOperatorAggregationsModel,
        segmentations?: ClassificationDTO[] | null,
        annualSalesRangeMappings?: CriterionParam[] | null,
        locale?: LocalesEnum,
        showPercents?: boolean,
    ) {
        if (locale) {
            this.locale = locale;
        }
        if (showPercents) {
            this.showPercents = showPercents;
        }
        if (apiData && segmentations && annualSalesRangeMappings) {
            this.mapFromApi(apiData, segmentations, annualSalesRangeMappings);
        }
    }

    private setData(model: OperatorAggregationsModel) {
        Object.assign(this, model);
    }

    private mapFromApi(
        apiData: ApiOperatorAggregationsModel,
        segmentations: ClassificationDTO[],
        annualSalesRangeMappings: CriterionParam[],
    ) {
        this.setData({
            chainsVsIndependents: this.parseChainsVsIndependents(apiData?.independent_vs_chain),
            segments: this.parseSegments(apiData?.segments, segmentations),
            annualSalesRange: this.parseAnnualSalesRange(apiData?.ranges, annualSalesRangeMappings),
            confidenceLevel: this.parseConfidenceLevel(apiData?.confidence_level),
        });
    }

    private parseConfidenceLevel(data: ApiOperatorAggregationsModel['confidence_level']): DonutSlice[] | null {
        if (!data || !data.length) {
            return null;
        }

        const total = data.reduce((sum, item) => sum + (item?.count ?? 0), 0);

        return data.map((item) => {
            const value = item?.count ?? 0;
            const percent = total ? (value / total) * 100 : 0;

            return {
                id: item?.key ?? '',
                value: value,
                percent: percent,
            };
        });
    }

    private parseChainsVsIndependents(data: ApiOperatorAggregationsModel['independent_vs_chain']) {
        if (!data || !data.length) {
            return null;
        }

        const apiKeyToTitle = {
            chain: OperatorTargetingResultsPhrasesEnum.ChainUnits,
            independent: OperatorTargetingResultsPhrasesEnum.IndependentUnits,
        };

        const chartSum = data.reduce((acc, item) => acc + item?.count, 0);

        return data.map((item) => {
            const phraseEnum = apiKeyToTitle?.[item?.key as keyof typeof apiKeyToTitle];
            const title = Dictionary?.[phraseEnum]?.[this.locale] ?? '';

            return {
                id: item?.key ?? '',
                title,
                formattedValue: localizeNumber(item?.count, this.locale),
                percentValue: getPercentageString(chartSum, item?.count),
            };
        }) as [ComparisonChartData, ComparisonChartData];
    }

    private parseAnnualSalesRange(
        data: ApiOperatorAggregationsModel['ranges'],
        annualSalesRangeMappings: CriterionParam[],
    ): VerticalProgressBarData[] | null {
        if (!data || !data.length) {
            return null;
        }

        const countMap: Record<string, number> = {};

        function flattenAndMapAggregation(aggregationData: ApiOperatorAggregationsModel['ranges']) {
            aggregationData
                ?.filter((data) => data?.description == RangesTypesEnum.AnnualSalesRangeType)
                ?.forEach((aggregation) => {
                    aggregation?.elements?.forEach((element) => {
                        if (element?.key && element?.count) {
                            countMap[element.key] = element?.count;
                        }
                    });
                });
        }
        flattenAndMapAggregation(data);

        const dataSum = Object.values(countMap).reduce((acc, val) => acc + val, 0);
        const dataMax = Math.max(...Object.values(countMap));

        return (
            annualSalesRangeMappings?.map((rangeDetails): VerticalProgressBarData => {
                const count = countMap[rangeDetails?.value ?? ''] ?? 0;
                return {
                    id: rangeDetails?.value ?? '',
                    title: rangeDetails?.name ?? '',
                    value: this.showPercents ? getPercentageString(dataSum, count) : localizeNumber(count, this.locale),
                    progress: (count / dataMax) * 100,
                };
            }) ?? []
        );
    }

    private parseSegments(
        data: ApiOperatorAggregationsModel['segments'],
        segmentations: ClassificationDTO[],
    ): BarChartGroup[] | null {
        if (!data || !data.length) {
            return null;
        }

        const countMap: Record<string, number> = {};

        function flattenAndMapAggregation(aggregationData: ApiOperatorAggregationsModel['segments']) {
            aggregationData?.forEach((aggregation) => {
                if (aggregation?.key && aggregation?.count) {
                    countMap[aggregation.key] = aggregation?.count;
                }

                // Stop recursion if the description is SUBCHANNEL
                if (aggregation?.children && !(aggregation.description === SegmentationsLevelEnum.SubChannel)) {
                    flattenAndMapAggregation(aggregation.children);
                }
            });
        }
        flattenAndMapAggregation(data);

        const HighestSubchannelCount = data.reduce((acc, item) => {
            const highestSubchannelCount = item?.children?.reduce((acc, item) => Math.max(acc, item?.count ?? 0), 0);
            return Math.max(acc, highestSubchannelCount ?? 0);
        }, 0);

        const totalSum = data.reduce((acc, item) => acc + (item?.count ?? 0), 0);

        return segmentations.map((segmentationGroup): BarChartGroup => {
            if (!segmentationGroup?.external_id) {
                return {
                    groupTitle: '',
                    bars: [],
                };
            }

            return {
                groupTitle: segmentationGroup?.description ?? '',
                bars:
                    segmentationGroup?.children?.map((segmentation, _): BarData => {
                        const count = countMap[segmentation?.external_id ?? ''] ?? 0;
                        return {
                            id: segmentation?.external_id ?? '',
                            title: segmentation?.description ?? '',
                            tooltipText: this.showPercents
                                ? getPercentageString(totalSum, count)
                                : localizeNumber(count, this.locale),
                            barPercentage: getPercentageString(HighestSubchannelCount, count),
                        };
                    }) ?? [],
            };
        });
    }
}
