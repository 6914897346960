import { InputMaybe, OperatorFilter, StringExpression } from '@graphql/generated/graphql';
import { CriterionParamFilter } from './Criterion';
import { isEmpty } from '@core/helpers/helpers';
import { containsSubstring } from '@core/utils/string';
import { OperatorFilterFactory } from './OperatorFilter';

const SEGMENT_KEY = CriterionParamFilter.Segment;

const SEGMENT_SPECIFIC_CODE_MATCH: { [key in CriterionParamFilter]?: string } = {
    [CriterionParamFilter.Cuisine]: 'S01',
    [CriterionParamFilter.EstMealsPerDayRange]: 'S01',
    [CriterionParamFilter.NumberOfRoomsRange]: 'S02',
    [CriterionParamFilter.HotelStarLevel]: 'S02',
};

type GroupedSegmentSpecificFilters = Record<string, OperatorFilter[]>;

export class OperatorSegmentsFilter {
    /**
     * This function processes logic related to custom or segment-specific filters.
     * @param operatorFilters An array of OperatorFilters.
     * @returns OperatorFilters after processing segment-specific filters.
     */
    static proccessSegments(operatorFilters: OperatorFilter[]): OperatorFilter[] {
        const segmentFilters = this.getOperatorFilterByKey(operatorFilters, SEGMENT_KEY);

        if (!segmentFilters) {
            return this.parseSegmentSpecificFiltersWithoutSegmentation(operatorFilters);
        }

        return this.parseSegmentSpecificFiltersWithSegmentation(operatorFilters, segmentFilters);
    }

    /**
     * This function retrieves the OperatorFilter that contains the key provided if it exists.
     *
     * @param filters OperatorFilter[]
     * @param key CriterionParamFilter
     * @returns OperatorFilter | undefined
     */
    static getOperatorFilterByKey(filters: OperatorFilter[], key: CriterionParamFilter): OperatorFilter | undefined {
        return filters.find((filter) => Object.keys(filter).includes(key));
    }

    private static parseSegmentSpecificFiltersWithoutSegmentation(operatorFilters: OperatorFilter[]): OperatorFilter[] {
        const unrelatedFilters = operatorFilters.filter((filter) =>
            Object.keys(filter).some((key) => {
                return !Object.keys(SEGMENT_SPECIFIC_CODE_MATCH).includes(key) && key !== SEGMENT_KEY;
            }),
        );

        const segmentSpecificFilters = this.proccessSegmentSpecificFiltersWithoutCodeMatch(operatorFilters);

        return [...unrelatedFilters, ...(segmentSpecificFilters.length > 0 ? [{ or: segmentSpecificFilters }] : [])];
    }

    private static parseSegmentSpecificFiltersWithSegmentation(
        operatorFilters: OperatorFilter[],
        allSegments: OperatorFilter,
    ): OperatorFilter[] {
        const segmentValues = this.getStringExpressionValues(allSegments?.[SEGMENT_KEY]);

        const unrelatedFilters = operatorFilters.filter((filter) =>
            Object.keys(filter).some((key) => {
                return !Object.keys(SEGMENT_SPECIFIC_CODE_MATCH).includes(key) && key !== SEGMENT_KEY;
            }),
        );

        const segmentSpecificFilters = this.proccessSegmentSpecificFiltersWithCodeMatch(operatorFilters, segmentValues);

        return [...unrelatedFilters, ...(segmentSpecificFilters.length > 0 ? [{ or: segmentSpecificFilters }] : [])];
    }

    private static proccessSegmentSpecificFiltersWithoutCodeMatch(operatorFilters: OperatorFilter[]): OperatorFilter[] {
        const segmentSpecificFiltersGroup = this.getGroupedSegmentSpecificFiltersWithoutCodeMatch(operatorFilters);

        if (segmentSpecificFiltersGroup) {
            const proccessedGroup = Object.entries(segmentSpecificFiltersGroup).flatMap(([codeMatch, filters]) => {
                const groupQuery: OperatorFilter = {
                    and: [{ segmentation: OperatorFilterFactory.buildStringExpression([codeMatch]) }, ...filters],
                };

                return groupQuery;
            });

            return proccessedGroup;
        }

        return [];
    }

    private static getGroupedSegmentSpecificFiltersWithoutCodeMatch(
        operatorFilters: OperatorFilter[],
    ): GroupedSegmentSpecificFilters | undefined {
        const segmentSpecificFilters = operatorFilters.filter((filter) =>
            Object.keys(filter).some((key) => {
                return Object.keys(SEGMENT_SPECIFIC_CODE_MATCH).includes(key);
            }),
        );

        const groupSegmentSpecificFilters = segmentSpecificFilters.reduce(
            (acc, filter) => {
                const currFilterCodeMatch = SEGMENT_SPECIFIC_CODE_MATCH[
                    Object.keys(filter)[0] as CriterionParamFilter
                ] as string;

                acc[currFilterCodeMatch] = [...(acc[currFilterCodeMatch] || []), filter];

                return acc;
            },
            {} as Record<string, OperatorFilter[]>,
        );

        return isEmpty(groupSegmentSpecificFilters) ? undefined : groupSegmentSpecificFilters;
    }

    private static proccessSegmentSpecificFiltersWithCodeMatch(
        operatorFilters: OperatorFilter[],
        segmentValues: string[],
    ): OperatorFilter[] {
        const segmentSpecificFiltersGroup = this.getGroupedSegmentSpecificFiltersWithCodeMatch(
            operatorFilters,
            segmentValues,
        );

        const groupKeys = Object.keys(segmentSpecificFiltersGroup ?? {});
        const otherSegments = segmentValues.filter((segment) => {
            return !groupKeys.some((key) => containsSubstring(segment, key));
        });

        if (segmentSpecificFiltersGroup) {
            const proccessedGroup = Object.entries(segmentSpecificFiltersGroup).flatMap(([codeMatch, filters]) => {
                const groupSegmentationMatches = segmentValues.filter((segment) =>
                    containsSubstring(segment, codeMatch),
                );

                const groupQuery: OperatorFilter = {
                    and: [
                        { segmentation: OperatorFilterFactory.buildStringExpression(groupSegmentationMatches) },
                        ...filters,
                    ],
                };

                return groupQuery;
            });

            return [
                ...proccessedGroup,
                ...(otherSegments.length > 0
                    ? [{ segmentation: OperatorFilterFactory.buildStringExpression(otherSegments) }]
                    : []),
            ];
        }

        return [
            ...(otherSegments.length > 0
                ? [{ segmentation: OperatorFilterFactory.buildStringExpression(otherSegments) }]
                : []),
        ];
    }

    private static getGroupedSegmentSpecificFiltersWithCodeMatch(
        operatorFilters: OperatorFilter[],
        segmentValues: string[],
    ): GroupedSegmentSpecificFilters | undefined {
        const segmentSpecificFilters = operatorFilters.filter((filter) =>
            Object.keys(filter).some((key) => {
                return Object.keys(SEGMENT_SPECIFIC_CODE_MATCH).includes(key);
            }),
        );

        const groupedSegmentSpecificFilters = segmentSpecificFilters.reduce(
            (acc, filter) => {
                const currFilterCodeMatch = SEGMENT_SPECIFIC_CODE_MATCH[
                    Object.keys(filter)[0] as CriterionParamFilter
                ] as string;

                const filterMatchesFromSegmentValues = segmentValues.filter((segment) =>
                    containsSubstring(segment, currFilterCodeMatch as string),
                );

                if (filterMatchesFromSegmentValues.length > 0) {
                    acc[currFilterCodeMatch] = [...(acc[currFilterCodeMatch] || []), filter];
                }

                return acc;
            },
            {} as Record<string, OperatorFilter[]>,
        );

        return isEmpty(groupedSegmentSpecificFilters) ? undefined : groupedSegmentSpecificFilters;
    }

    /**
     * This function retrieves the values from a StringExpression. It will return an empty array if the expression is null or undefined.
     * Currently, our use of the StringExpression can be of type equals or in.
     *
     * @param expression InputMaybe<StringExpression> from GraphQL Codegen generated types.
     * @see StringExpression @see InputMaybe
     * @returns string[] of the values from the expression.
     */
    static getStringExpressionValues(expression: InputMaybe<StringExpression>): string[] {
        if (!expression) {
            return [];
        }

        if ('equals' in expression && expression.equals) {
            return [expression.equals];
        }

        if ('in' in expression && expression.in) {
            return expression.in;
        }

        return [];
    }
}
