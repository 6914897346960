import { FC } from 'react';

import {
    FilterTypeEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    TableColumnsEnum,
} from '@enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import { useSearchInput } from '@core/hooks/useInput';
import { generateAnchorId } from '@core/utils/string';
import { IconFilterRestaurants } from '@assets/icons';
import { RangesTypesEnum } from '@core/api/useRanges';
import { GradientColorsEnum } from '@enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import SearchInput from '@components/utility/Inputs/SearchInput/SearchInput';
import { FILTER_ID_PREFIX } from '@pages/OperatorTargetingCriteria/hooks/useSearchNavigation';

import CuisinesChannel from './CuisinesChannel';
import CuisinesSwitchList from './CuisinesSwitchList';
import { RangesData } from '../GeneralCriteria/General.helpers';
import { useCuisinesCriteria } from '../../../hooks/useCuisinesCriteria';

const RestaurantsAndBarsCriteria: FC<{
    ranges: RangesData | null;
}> = ({ ranges }) => {
    const { t } = useLocale();
    const { onResetCriterionParamsByKey } = useAdvancedSearch();
    const {
        channelList,
        searchInputOptions
    } = useCuisinesCriteria();

    const {
        value: searchSelectedValue,
        onChange
    } = useSearchInput();

    const isEstMealsPerDayEnabled = useFeatureFlag(FeatureFlags.EstMealsPerDay);

    return (
        <div
            className="CriteriaContent"
            id={generateAnchorId(t(FilterTypeEnum.RestaurantsAndBars))}
            style={{ marginBottom: '10px' }}
        >
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterRestaurants />} baseColor={GradientColorsEnum.PURPLE} />
                <Heading>{t(FilterTypeEnum.RestaurantsAndBars)}</Heading>
                <SearchInput
                    options={searchInputOptions}
                    highlightResults
                    onChangeCallback={onChange}
                    value={searchSelectedValue}
                    testId="CuisineTypesSearch"
                />

                <Button
                    modifiers={['naked']}
                    type={ButtonTypeEnum.Link}
                    onClick={() => onResetCriterionParamsByKey(CriterionEnum.CuisineTypes)}
                    style={{ width: 'auto', marginLeft: 'auto' }}
                    testId="ButtonCuisineTypesResetAll"
                >
                    {t(OperatorTargetingPhrasesEnum.ResetAll)}
                </Button>
            </div>

            <Widget
                type={WidgetType.Section}
                className="criteria"
                id={generateAnchorId(t(TableColumnsEnum.Cuisine), FILTER_ID_PREFIX)}
                dataObservable
            >
                <Heading variant={HeadingVariant.H5} testId={FilterTypeEnum.CuisineTypes}>
                    {t(FilterTypeEnum.CuisineTypes)}
                </Heading>
                {channelList?.map((channel) => {
                    return (
                        <CuisinesChannel
                            searchSelectedValue={searchSelectedValue}
                            key={channel?.description}
                            channel={channel}
                            onClearSearch={() => {
                                onChange(null);
                            }}
                        />
                    );
                })}
            </Widget>
            {!!ranges?.[RangesTypesEnum.EstMealsPerDayRangeType] && isEstMealsPerDayEnabled && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria GeneralCriteria__section"
                    id={generateAnchorId(t(OperatorProfilePhrasesEnum.EstMealsPerDay), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <CuisinesSwitchList
                        range={ranges[RangesTypesEnum.EstMealsPerDayRangeType]}
                        testId={OperatorProfilePhrasesEnum.EstMealsPerDay}
                        title={`${t(OperatorProfilePhrasesEnum.EstMealsPerDay)}`}
                        subtitle={` (${t(OperatorProfilePhrasesEnum.AverageDailyMeals).toLocaleLowerCase()})`}
                        criterionFilterKey={CriterionParamFilter.EstMealsPerDayRange}
                    />
                </Widget>
            )}
        </div>
    );
};

export default RestaurantsAndBarsCriteria;
