import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { useAdvancedSearch } from '@core/contexts';
import Tag from '@components/utility/Chips/Tag/Tag';
import { toggleInArray } from '@core/helpers/helpers';
import { generateAnchorId } from '@core/utils/string';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { TagData, TagsCategoryEnum, TagsResponseContent } from '@core/api/useTags';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

function mapTagDataToDropdownOption(tag: TagData): DropdownOption {
    return {
        id: tag.code,
        value: tag.code,
        label: tag.description,
    };
}

const TagsCategoryList: FC<{
    tagsData: TagsResponseContent;
}> = ({ tagsData }) => {
    const [selectedTags, setSelectedTags] = useState<ID[]>([]);
    const { t } = useLocale();
    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey,
        onResetCriteriaByFilterKey
    } = useAdvancedSearch();

    const tagCategory = useMemo(() => {
        if (tagsData.category === TagsCategoryEnum.FOOD) {
            return { phrase: OperatorProfilePhrasesEnum.FoodCuisine, paramFilter: CriterionParamFilter.FoodTags };
        }

        return { phrase: OperatorProfilePhrasesEnum.Place, paramFilter: CriterionParamFilter.PlaceTags };
    }, [tagsData.category]);

    useEffect(() => {
        const tagParams = getCriterionByFilterKey(CriterionEnum.Tags, tagCategory.paramFilter);

        const selectedTagParamsIds = tagsData.children
            .filter((tag) => {
                return tagParams?.some((tagParam) => tagParam?.value === tag.code);
            })
            .map((tag) => tag.code);

        setSelectedTags(selectedTagParamsIds);
    }, [getCriterionByFilterKey, tagCategory.paramFilter, tagsData.children]);

    const onAddTags = useCallback(
        (tagsList: TagData[]) => {
            tagsList?.forEach((tag) => {
                const tagPill: DropdownOption = mapTagDataToDropdownOption(tag);
                addCriterionOnOptionSelected(CriterionEnum.Tags, tagCategory.paramFilter)(tagPill);
            });
        },
        [addCriterionOnOptionSelected, tagCategory.paramFilter],
    );

    const onUpdateSelectedTags = useCallback(
        (tagId: ID) => {
            const newSelectedTags = toggleInArray(selectedTags, tagId);
            setSelectedTags(newSelectedTags);

            onResetCriteriaByFilterKey(CriterionEnum.Tags, tagCategory.paramFilter);

            const activeCriteriaTags = tagsData.children.filter((tag) => {
                return newSelectedTags.includes(tag.code);
            });

            onAddTags(activeCriteriaTags);
        },
        [onAddTags, onResetCriteriaByFilterKey, selectedTags, tagCategory.paramFilter, tagsData.children],
    );

    return (
        <div className="CriteriaContent__section" id={generateAnchorId(t(tagCategory.phrase))} data-observable>
            <Widget type={WidgetType.Collapse} className="criteria">
                <div className="CriteriaContent__heading CriteriaContent__heading--section">
                    {t(tagCategory.phrase)}
                </div>

                <div className={'CriteriaContent__columns col-1'}>
                    <div className={'CriteriaContent__column col-1'}>
                        <div className="TagsContent__tag-container">
                            {tagsData.children.map((tag) => (
                                <Tag
                                    key={tag.code}
                                    name={tag.description}
                                    testId={tag.description}
                                    isChecked={selectedTags.includes(tag.code)}
                                    baseColor={GradientColorsEnum.ORANGE}
                                    onChange={() => {
                                        onUpdateSelectedTags(tag.code);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Widget>
        </div>
    );
};

export default TagsCategoryList;
