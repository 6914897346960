import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { OperatorTargetingPhrasesEnum, PhrasesEnum } from '@core/enums/localeEnum';
import { AlphaNumericSpaceAndHyphenRegex, NumericSpaceAndHyphenRegex, ZIP_COUNTRIES } from './Zip.constants';
import { ZipItem } from './useZip';
import { DropdownOption } from '../Dropdowns/Dropdowns.helpers';
import { CriterionParam } from '@core/models/Criterion';

export type ZipValidation = {
    isAlphaNumeric?: boolean;
    maxLength: number;
    pattern?: string;
    regex: RegExp;
};

export function maskZip(value: string, country: CountryCodesEnum): string {
    const {
        pattern,
        isAlphaNumeric,
        maxLength
    } = getValidation(country);
    const allowedCharactersRegex = isAlphaNumeric ? AlphaNumericSpaceAndHyphenRegex : NumericSpaceAndHyphenRegex;
    const newValue = value.replace(allowedCharactersRegex, '');

    if (pattern === undefined) {
        return newValue.slice(0, maxLength).toUpperCase();
    }

    return applyPattern(newValue, pattern).toUpperCase();
}

function applyPattern(value: string, pattern: string): string {
    let maskedValue = '';
    let valueIndex = 0;

    for (let patternIndex = 0; patternIndex < pattern.length && valueIndex < value.length; patternIndex++) {
        const currentChar = value[valueIndex];
        const patternChar = pattern[patternIndex];

        if (shouldAddPatternChar(currentChar, patternChar)) {
            maskedValue += patternChar;
            if (isPlaceholder(patternChar)) {
                valueIndex++;
            }
        } else {
            if (!isSpaceOrHyphen(currentChar)) {
                maskedValue += currentChar;
            }
            valueIndex++;
        }
    }
    return maskedValue;
}
function shouldAddPatternChar(valueChar: string, patternChar: string): boolean {
    return patternChar === '-' || (patternChar === ' ' && !isSpaceOrHyphen(valueChar));
}

function isSpaceOrHyphen(char: string): boolean {
    return char === '-' || char === ' ';
}

function isPlaceholder(char: string): boolean {
    return char !== '-' && char !== ' ';
}

export function sanitizeZip(value: string): string {
    return value.replace(/[\s-]/g, '');
}

export function getErrorMessages(country: CountryCodesEnum): {
    duplicate: PhrasesEnum;
    invalid: PhrasesEnum;
    noPermission: PhrasesEnum;
} {
    switch (country) {
        case CountryCodesEnum.US:
            return {
                duplicate: OperatorTargetingPhrasesEnum.DuplicateZip,
                invalid: OperatorTargetingPhrasesEnum.InvalidZip,
                noPermission: OperatorTargetingPhrasesEnum.InvalidZipPermission,
            };
        default:
            return {
                duplicate: OperatorTargetingPhrasesEnum.DuplicatePostalCode,
                invalid: OperatorTargetingPhrasesEnum.InvalidPostalCode,
                noPermission: OperatorTargetingPhrasesEnum.InvalidPostalCodePermission,
            };
    }
}

export function getValidation(country: CountryCodesEnum): ZipValidation {
    return ZIP_COUNTRIES?.[country] ?? ZIP_COUNTRIES.DEFAULT;
}

export function mapZipToDropdownOption(zip: ZipItem): DropdownOption {
    const {
        formattedValue,
        value
    } = zip;

    return {
        label: formattedValue,
        id: value,
        value: value.toString(),
    };
}

export function mapCriterionParamsToZipItem(params: CriterionParam[]): ZipItem[] {
    return params.map(({
        value,
        name
    }) => {
        return {
            value: value,
            formattedValue: name.toString(),
        };
    });
}
