import { type FC, useCallback } from 'react';

import { IconExport } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { useExportOperatorsContext } from '@core/contexts';
import Input from '@components/utility/Inputs/Input/Input';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import NumberAnimation from '@components/utility/NumberAnimation/NumberAnimation';
import { CommonPhrasesEnum, ExportPhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';
import { useAppContext } from '../../../../App.context';

import OperatorTargetingResultsTable from '../OperatorResultsTable/OperatorResultsTable';

const OperatorResultsList: FC = () => {
    const { t } = useLocale();

    const {
        isExportLoading,
        fetchFilteredOperators
    } = useExportOperatorsContext();

    const {
        operatorResultsList: {
            listFilters,
            loading,
            resultsCount,
            setSearchTerm,
            searchTerm
        },
    } = useOperatorTargetingResultsContext();

    const handleSearchChange = useCallback(
        (searchTermValue: string) => setSearchTerm(searchTermValue),
        [setSearchTerm],
    );

    const {
        userInfo: { exportAllowed },
    } = useAppContext();

    return (
        <>
            <div className="OperatorResultsTab">
                <div className="OperatorResultsTab__content">
                    <div className="OperatorResultsTab__search-wrapper">
                        <Input
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={t(OperatorTargetingPhrasesEnum.SearchByInputPlaceholder)}
                            withClearIcon
                            isLoading={loading}
                            testId="TableSearchInput"
                        />
                    </div>

                    {exportAllowed && (
                        <Button
                            onClick={() => {
                                if (listFilters && !isExportLoading) {
                                    fetchFilteredOperators(listFilters);
                                }
                            }}
                            type={ButtonTypeEnum.Button}
                            modifiers={['tertiary']}
                            className="OperatorResultsTab__button"
                            isDisabled={isExportLoading}
                        >
                            {isExportLoading ? (
                                <div className="OperatorResultsTab__button-loader small" />
                            ) : (
                                <IconExport />
                            )}
                            {t(ExportPhrasesEnum.Export)}
                        </Button>
                    )}

                    {/* Display Drawer is not included in scope */}
                    {/* <Button
                        onClick={() => setIsDrawerOpen(true)}
                        type={ButtonTypeEnum.Button}
                        modifiers={['dropdown']}
                        className={cn(isDrawerOpen && 'active')}
                    >
                        <IconColumns />
                        Display
                    </Button> */}
                </div>
                <div className="OperatorResultsTab__results-count" data-testid="tableResultsCount">
                    <NumberAnimation className="OperatorResultsTab__results-count-numbers" count={resultsCount} />
                    {t(CommonPhrasesEnum.Results)}
                </div>
            </div>

            <OperatorTargetingResultsTable />

            {/* Display Drawer is not included in scope */}
            {/* <DisplayDrawer
                columns={drawerColumnsData}
                isDrawerOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            /> */}
        </>
    );
};

export default OperatorResultsList;
